<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Directory | Websites </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li><router-link :to="{name:'websites', params:{ WebType:'All'}}">Websites</router-link></li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="12"><b-form-input v-model="SearchBy" placeholder="Search by Website name"></b-form-input></b-col>
                    <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
                  </b-row>    
                </div>
                <div class="w-100 mt-4"></div>
                <!-- Portfolio card Listing goes here -->
                <div v-for="Data in SearchList" v-bind:key="Data.id">
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-12 Thira profile-card-5">
                      <h5 class="pl-3 mt-3 pr-3"><b-link v-bind:href="'http://'+Data.web_url" target="_blank" title="Click here to go to website">www.{{Data.web_url}}</b-link></h5>
                      <p class="card-text pre-formatted pl-3 mt-0 pr-4" v-if="Data.web_title.length<180"><span v-html="Data.web_title"></span>
                          <p class="card-text pre-formatted pl-3 mt-0 pr-4" v-else><span v-html="Data.web_title.substring(0,180)+' ...'"></span></p> 
                    </div>

                  </b-row>    
                </div>                
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar search box -->
                <!-- <h3 class="sidebar-title">Search</h3>
                <div class="sidebar-item search-form">
                  <form action="">
                    <input type="text">
                    <button type="submit"><i class="icofont-search"></i></button>
                  </form>
                </div> -->
                <!-- sidebar Categories -->
                <h3 class="sidebar-title">Categories</h3>
                <div class="sidebar-item categories">
                  <ul v-for="Cnts in RespArrayCnts" v-bind:key="Cnts.id">
                    <!-- <li><a @click="ReadSelectedwebsite(Cnts.web_type)"> {{Cnts.web_type}}<span> ({{ Cnts.rec_count}})</span></a></li> -->
                    <li><router-link :to="{name:'websites', params:{ WebType: Cnts.web_type}}"><a> {{Cnts.web_type}}<span> ({{ Cnts.rec_count}})</span></a></router-link></li>                  
                  </ul>
                </div> 
                <!-- sidebar recent posts-->
                <SideRecentPerformers/>
              </div>

            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"Websites",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Website Directory | Performing Arts | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },     
    data() {
      return {
        SearchBy: "",
        RespArray: [],
        RespArrayCnts: [],
      }
    },
    watch: {
       '$route' (to)
       {
        if(this.$route.params.WebType == 'All') {
          this.ReadAllWebsite(to.params.WebType)
        }
        else {
          this.ReadSelectedwebsite(to.params.WebType)
        }                 
       }
    },    
    mounted()
    {
      this.ReadCountWebsite();
      if(this.$route.params.WebType == 'All') {
        this.ReadAllWebsite()
      } 
      else {
        this.ReadSelectedwebsite(this.$route.params.WebType)
      }      
    },
    methods: {
      ReadAllWebsite(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllWebsite')
        .then((resp)=>{
        this.RespArray=resp.data.AllWebsite;
      })
      },
      ReadCountWebsite(){     
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadCountWebsite')
        .then((resp)=>{
        this.RespArrayCnts=resp.data.WebsiteCnts;
      })
      },
      ReadSelectedwebsite(WebType){     
        const REQformData = new FormData();
        REQformData.append('web_type', WebType);               
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadSelectedwebsite', REQformData)
        .then((resp)=>{
        this.RespArray=resp.data.AllWebsite;
      })
      },           
    },
    computed: {
    SearchList: function(){
        return this.RespArray.filter((RespArray) => {
        return RespArray.web_url.toLowerCase().match(this.SearchBy.toLowerCase())
      });
    },   
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    border-radius:5px;
    height: 80px;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:600;
    font-size:16px;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
}
.profile-card-5 a{
    color:#e75480;
    font-weight:600;
    font-size:16px;
}
.profile-card-5 a:hover{
    color:#ff004c;
}

.pre-formatted {
  white-space: pre-line;
  text-align: justify;
  display: inline-block;
}
</style>
